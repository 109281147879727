import * as React from "react";
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

const DefaultCreateRelatedButton = props => {
    let _state = {
        pathname: `/${props.targetResource}/create`,
        state: {
            record: {}
        }
    }
    _state.state.record[props.targetResourceKey] = props.record.id
    return <Button
        component={Link}
        to={_state}
        startIcon={<AddIcon/>}
        color={"primary"}
    >
        {props.text}
    </Button>
};

export default DefaultCreateRelatedButton;
