export const getDataProviderMap = (provider, resource, params) => {
    return new Map([
        ['GET_LIST', async () => {
            return await provider.getList(resource, params);
        }],
        ['GET_ONE', async () => {
            return await provider.getOne(resource, params);
        }],
        ['GET_MANY', async () => {
            return await provider.getMany(resource, params);
        }],
        ['GET_MANY_REFERENCE', async () => {
            return await provider.getManyReference(resource, params);
        }],
        ['UPDATE', async () => {
            return await provider.update(resource, params);
        }],
        ['UPDATE_MANY', async () => {
            return await provider.updateMany(resource, params);
        }],
        ['CREATE', async () => {
            return await provider.create(resource, params);
        }],
        ['DELETE', async () => {
            return await provider.delete(resource, params);
        }],
        ['DELETE_MANY', async () => {
            return await provider.deleteMany(resource, params);
        }]
    ]);
}