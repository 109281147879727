import {httpClient, RestProvider} from './coreApiDataProvider'
import {getDataProviderMap} from "../utils/getDataProviderMap";
import {dataProviderConfig} from "../config/dataProvidersConfig";

const authApiDataProvider = (type, resource, params) => {
    const apiUrl = dataProviderConfig.authApiDataProvider;
    const provider = {...RestProvider(apiUrl)};
    const providerMap = getDataProviderMap(provider, resource, params);
    return providerMap.get(type)(resource, params);
};

export {authApiDataProvider, httpClient, RestProvider}