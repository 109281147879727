import {fetchUtils} from 'react-admin';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    const token = localStorage.getItem('token')
    options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count'); //<----see here
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options);
}

const promisePlaceholder = new Promise((resolve, reject) => {
    resolve({data: {id: 0}})
    reject("error")
});

const RestProvider = (apiUrl) => {
    return {
        // apiUrl: apiUrl,
        create: function (resource, params) {
            // console.log(params.data)
            let url
            let payload
            url = `${apiUrl}/${resource}`
            payload = [params.data]
            
            // if json is a list of one element, return element, else error "this implementation of create should return only one element"
            const create_result = httpClient(url, {method: 'POST', body: JSON.stringify(payload)}).then(({json}) => ({
                    data: json.length === 1 ? json[0] :
                        () => {throw Error("this implementation of create should return only one element")}
                })
            )
            const result = new Promise((resolve, reject) => {
                resolve(create_result)
                reject("error")
            })
            // console.log('create result', result)
            return result;
        },
        getList: function (resource, params) {
            function makeFilterQueryString(filters) {
                const parsedFilters = {}
                let result = ''
                for (const k in filters) {
                    let newKey = `filter[${k}:eq]`
                    parsedFilters[newKey] = filters[k]
                }
                for (const k in parsedFilters) {
                    result += `&${k}=${parsedFilters[k]}`
                }
                return result
            }
            // query: 'page[offset]=3&page[limit]=5&sort=id,shipment_id'
            // console.log('getList called', resource, params);
            // console.log('get list', resource, params)
            const {page, perPage} = params.pagination;
            let {field, order} = params.sort;

            // change keys according to backend
            const filters = params.filter
            if (!field) {
                field = 'id'
            }
            
            if (order === 'DESC') {
                field = '-' + field
            }
            
            // const limit = perPage
            const offset = perPage * (page - 1)
            const filterQuery = makeFilterQueryString(filters)
            const query_str = `sort=${field}${filterQuery}`
            
            const url = `${apiUrl}/${resource}?${query_str}`;
            // console.log(url)
            
            return httpClient(url).then(
                ({json}) => ({
                    data: json.slice(offset, offset + perPage),
                    total: json.length,
                })
            );
        },
        getOne: function (resource, params) {
            // console.log('getOne called', resource, params);
            const identifierValue = params.id;

            const url = `${apiUrl}/${resource}/${identifierValue}`
            return httpClient(url).then(
                ({json}) => ({
                    data: json
                })
            )
        },
        getMany: function (resource, params) {
            // console.log('getMany called', resource, params);
            let result
            let url

            url = `${apiUrl}/${resource}`
            result = httpClient(encodeURI(url)).then(
                ({json}) => ({
                    data: json
                })
            )
            // console.log('getMany result', result)
            return result
        },
        getManyReference: function (resource, params) {
            // console.log('get many reference resource', resource, params)
            const currentUrl = window.location.href;
            const parentIdentifierValue = currentUrl.split("/").pop();

            const url = `${apiUrl}/${resource}?filter[${params.target}:eq]=${Number(parentIdentifierValue)}`
            // console.log(url)
            const result = httpClient(encodeURI(url)).then(
                ({json}) => ({
                    data: json,
                    total: json.length
                })
            );
            // console.log('get many reference resource', resource, result)
            return result
        },
        update: function (resource, params) {
            // we call the update_many endpoint passing a list of one element. Similar to the create method.
            const url = `${apiUrl}/${resource}`
            // console.log('update one called', resource, params, url);
            const result = httpClient(url, {method: 'PATCH', body: JSON.stringify([params.data])}).then(({json}) => ({
                    data: json.length === 1 ? json[0] :
                        () => {throw Error("this implementation of update should return only one element")}
                })
            )
            return new Promise((resolve, reject) => {
                resolve(result)
                reject("error")
            });
        },
        updateMany: function (resource, params) {
            return promisePlaceholder
        },
        delete: function (resource, params) {
            return promisePlaceholder
        },
        deleteMany: function (resource, params) {
            return promisePlaceholder
        }
    }
}

export {httpClient, RestProvider}