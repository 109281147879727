import {EditButton} from "react-admin";
import React from "react";

const DefaultEditButton = (props) => {
    let to_resource
    if (['user_permissions'].includes(props.resource)) {
        to_resource = `user_permissions`
    } else if (['user_group_enrollments'].includes(props.resource)) {
        to_resource = `user_group_enrollments`
    }
    else {
        to_resource = props.resource
    }
    return (
        <EditButton to={`/${to_resource}/${props.record.id}`}/>
    )
}

export default DefaultEditButton
