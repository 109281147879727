const defaultButtonHandler = (event, options) => {
    options.notify(options.defaultValue)
}

const createRelatedButtonHandler = (event, options) => {
    event.stopPropagation();
    const to_url = `${options.targetResource}/create`;
    window.location.href = to_url;
}

const mapping = {
    'defaultButtonHandler': defaultButtonHandler,
    'createRelatedButtonHandler': createRelatedButtonHandler
}

export default mapping
